
.bgimage-content {
  &, & * {
    box-sizing: border-box;
  }
  padding: 10px;
  .bgm-box {
      margin-top: 20px;
  }

}

